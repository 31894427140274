import { H1 } from '../components/shared/typography';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { StyledContent } from '../components/layout/layout';
import { graphql, } from 'gatsby';
import Seo from '../components/Seo';

type Props = {
  data: {
    allContentfulRefund: {
      nodes: any;
    };
  };
};

const Privat: React.FC<Props> = (props: Props) => {
  const content = props.data.allContentfulRefund.nodes[0];

  return (
    <StyledContent style={{marginTop: '2rem'}}>
      <Seo 
        title='Handelsbetingelser'
        description='Mansted wine Handelsbetingelser'
      />
      <H1>{content.refund}</H1>
      <ReactMarkdown>{content.text.text}</ReactMarkdown>
    </StyledContent>
  );
};

export default Privat

export const RefundPage = graphql`
  query RefundPage {
    allContentfulRefund {
      nodes {
        refund
        text {
          text
        }
      }
    }
  }
`;
